import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { DefaultMenuItemsProps } from '@pp/common/components/navigation/sidebar/components/sidebar-menus/analyze-menu/AnalyzeItems'
import { slideOutCss, submenuCss } from '@pp/common/components/navigation/sidebar/sidebar.css'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { Menu } from 'semantic-ui-react'

const ToolsItems = ({ menus, minimizedSideBar, isMobile, active, onNavLinkClick }: DefaultMenuItemsProps) => {
  const { applicationStore } = useStores()

  const handleClick = (route: string) => {
    if (route === '') {
      applicationStore.setModal({ open: true, version: '' })
    } else {
      onNavLinkClick(route)
    }
  }

  return (
    <Menu.Menu className={cx(submenuCss(false, isMobile), !minimizedSideBar && slideOutCss(menus.toolsMenu))}>
      {applicationStore.hasExcelAutofill && (
        <Menu.Item>
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.excelAutofill) ? 'active' : ''}
              onClick={() => handleClick(routes.excelAutofill)}
            >
              <div className="notch"></div>
              <Trans id="sidebar.buttons.excelAutofill" />
            </span>
          </div>
        </Menu.Item>
      )}
      {applicationStore.hasCustomCharts && (
        <Menu.Item>
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.buildYourOwnChartsRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.buildYourOwnChartsRoute)}
            >
              <div className="notch"></div>
              <Trans id="sidebar.buttons.buildYourOwnCharts" />
            </span>
          </div>
        </Menu.Item>
      )}
    </Menu.Menu>
  )
}

export default observer(ToolsItems)
