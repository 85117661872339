export type FamilyType = 'T' | 'P'

export enum BuilderPurpose {
  'CASE_EXCHANGE' = 'CASE_EXCHANGE',
  'OVERVIEW' = 'OVERVIEW',
  'VALIDATION' = 'VALIDATION',
}

export enum LanguageEnum {
  'CN' = 'zh-CN',
  'EN' = 'en-US',
}

export type ResultsGrouping = 'single' | 'group'
