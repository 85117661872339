import { SyntheticEvent, useEffect, useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import { css, cx } from '@emotion/css'
import { t } from '@lingui/macro'
import { printCss } from '@pp/common/chart/config/charts-config'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { useStores } from '@pp/store/useStore.hook'
import { Popup, Form, Input, Button, Dropdown } from 'semantic-ui-react'

import { useIsChartSaved } from './useIsChartSaved.hook'
import Icon from '../../../../../components/icon/Icon'
import { mapBuilderPurpose, prepareFilters } from '../../helpers'

type Props = { chartType?: string; testId: string; title?: string }

const SAVE_CHART_CONFIGURATION = gql`
  mutation saveChartConfiguration($chartConfiguration: ChartConfigurationInput!) {
    saveChartConfiguration(chartConfiguration: $chartConfiguration) {
      name
      chartType
      chartFilter
    }
  }
`

const SaveToDashboard = ({ chartType, title }: Props) => {
  const { applicationStore, filterStore } = useStores()
  const { isChartSaved, entities, entityType } = useIsChartSaved({ chartType })
  const [checkmark, setCheckmark] = useState<boolean>(isChartSaved)
  const [chartName, setChartName] = useState<string>(title?.replace('<br>', ' ') || '')
  const [saveChartConfiguration, { loading: saveLoading, data }] = useMutation(SAVE_CHART_CONFIGURATION)

  const onSaveToDashboard = () => {
    const mappedActiveFilters = mapBuilderPurpose(filterStore.activeFilters, chartType)
    const chartFilter = JSON.stringify(
      prepareFilters(mappedActiveFilters, entityType, applicationStore.metadata, entities),
    )
    saveChartConfiguration({ variables: { chartConfiguration: { name: chartName, chartType, chartFilter } } })
  }

  const onDashboardNameChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setChartName(event.currentTarget.value)
  }

  useEffect(() => {
    isChartSaved && setCheckmark(isChartSaved)
  }, [isChartSaved])

  useEffect(() => {
    if (data?.saveChartConfiguration) {
      setCheckmark(true)
      applicationStore.setChartConfigurations(data?.saveChartConfiguration)
    }
  }, [applicationStore, data])

  if (applicationStore.hasDashboards)
    return (
      <Dropdown
        inline
        icon={null}
        direction="left"
        trigger={
          <Popup
            content={t`saveToDashboard.addIcon.title`}
            trigger={
              <Icon
                iconName="icon-dashboard"
                color={checkmark ? 'var(--primary-color)' : '#4c4c4c'}
                size={14}
                margin="0 0 0 8px"
              />
            }
            on="hover"
          />
        }
        className={cx(
          css`
            ${increaseSpecificity(`
              border: none !important;
              padding: 0 !important;
              min-height: 20px !important;
              height: 20px !important;
            `)}
          `,
          printCss,
        )}
      >
        <Dropdown.Menu>
          <div
            className={css`
              padding: 8px 16px 16px 16px;
            `}
          >
            <Form onSubmit={onSaveToDashboard}>
              <Form.Field>
                <label>Chart Name</label>
                <Input
                  placeholder="Chart Name"
                  type="text"
                  name="chart-name"
                  value={chartName}
                  onChange={onDashboardNameChange}
                />
              </Form.Field>
              <Button type="submit" secondary disabled={chartName === ''} loading={saveLoading}>
                Add to Dashboard
                {checkmark ? (
                  <Icon iconName="icon-checkmark" size={14} color="var(--primary-color)" margin="0 0 0 8px" />
                ) : null}
              </Button>
            </Form>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    )

  return (
    <Icon
      iconName="icon-dashboard"
      color={checkmark ? 'var(--primary-color)' : '#4c4c4c'}
      size={14}
      margin="0 0 0 8px"
      onClick={() => applicationStore.setModal({ open: true, version: '' })}
    />
  )
}

export default SaveToDashboard
