import { useEffect } from 'react'

import { ResultsGrouping } from '@pp/modules/analyse/common/types/app.type'
import { useStores } from '@pp/store/useStore.hook'
import { useLocation } from 'react-router'

export const useSetResultsGrouping = () => {
  const { applicationStore } = useStores()
  const { search } = useLocation()

  const onSetResultsGrouping = (value: ResultsGrouping) => {
    applicationStore.setResultsGrouping(value)
  }

  useEffect(() => {
    if (search.includes('group_search')) {
      const params = new URLSearchParams(search)
      const groupSearch = params.get('group_search')
      const newValue: ResultsGrouping = groupSearch === 'true' ? 'group' : 'single'
      onSetResultsGrouping(newValue)
    }
    // eslint-disable-next-line
  }, [search])
}
