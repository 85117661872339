import * as routes from '@pp/routing/routes'

export const dashboardRoutes = [routes.dashboardsRoute]
export const buildYourOwnChartsRoutes = [routes.buildYourOwnChartsRoute]
export const analyzeRoutes = [routes.agentsRoute, routes.applicantsRoute, routes.attorneysRoute]
export const searchRoutes = [routes.advancedSearchRoute, routes.smartSearchRoute]
export const resourcesRoutes = [routes.resourcesRoute]
export const checkRoutes = [
  routes.whitespotCheckRoute,
  routes.filingCheckRoute,
  routes.similarityRoute,
  routes.mutualityRoute,
]
export const toolsRoutes = [routes.excelAutofill]
