import { ReactNode } from 'react'

import { ApolloError } from '@apollo/client'
import { css } from '@emotion/css'
import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { mobileMediaQuery } from '@pp/common/css/css.helper'
import { getPageInfo } from '@pp/common/helpers'
import { useStores } from '@pp/store/useStore.hook'
import { useMediaQuery } from '@react-hook/media-query'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'

import { ChartContent, ChartHeader } from './components'

export enum ExportChartType {
  JPEG,
  SVG,
  PDF,
  XLSX,
}

interface ChartHolderProps {
  centered?: boolean
  chartType?: string
  children: ReactNode
  datesDropDown?: JSX.Element
  error?: null | ApolloError
  fieldsDropDown?: JSX.Element
  hasDashboard?: boolean
  hasExport?: boolean | ExportChartType[]
  id?: string
  insightsTab?: boolean
  isBeta?: boolean
  isProfile?: boolean
  loading?: boolean
  mobileWarning?: JSX.Element | null
  noData?: boolean | null
  onExport?: (payload: ExportChartType) => void
  spinnerHeight?: string
  testId: string
  title?: string
  tooltip?: string
}

export const defaultExportType = [ExportChartType.JPEG, ExportChartType.SVG, ExportChartType.PDF, ExportChartType.XLSX]
export const premiumFeatureColorCss = css({ color: 'rgb(151, 130, 3) !important' })
export const premiumMessageCss = css({
  textTransform: 'none',
  fontWeight: 'normal',
  color: 'rgb(151, 130, 3) !important',
  fontSize: '1rem',
})

function ChartHolder({
  children,
  title = '',
  tooltip,
  testId,
  isBeta,
  hasExport = true,
  onExport,
  noData,
  loading,
  error,
  spinnerHeight,
  chartType,
  fieldsDropDown,
  datesDropDown,
  hasDashboard = true,
  mobileWarning,
  centered = false,
  insightsTab = false,
  id,
  isProfile = true,
}: ChartHolderProps) {
  const isMobile = useMediaQuery(mobileMediaQuery)
  const { applicationStore } = useStores()
  const { pageName } = getPageInfo(window.location.pathname)
  const isPdfExport = applicationStore.isPdfExport
  const location = useLocation()
  const entityType = chartType?.startsWith('lf')
    ? EntityType.Agent
    : chartType?.startsWith('at')
    ? EntityType.Attorney
    : EntityType.Applicant
  const entities = entityType === EntityType.Agent ? applicationStore.agentUrls : applicationStore.applicantUrls
  const hasCustomView = applicationStore.hasCustomView
  const isDashboard = location.pathname.includes('/dashboards')

  const trackingData = {
    page: pageName,
    entities: entities,
    title: title || '',
    measureMount: false,
    measureUnMount: true,
  }

  return (
    <div
      className={css`
        border: ${isPdfExport ? 'none' : '1px solid #e6e6e6'};
        border-radius: 8px;
        padding: ${isPdfExport ? '0' : isMobile ? '8px' : '12px'};
        height: 100%;
        background-color: ${insightsTab ? 'white' : 'transparent'};
      `}
    >
      {!isDashboard ? (
        <ChartHeader
          chartType={chartType}
          jsxElements={{ datesDropDown, fieldsDropDown, mobileWarning }}
          onExport={onExport}
          permissions={{ hasDashboard, hasCustomView, hasExport, isBeta, isPdfExport }}
          testId={testId}
          title={title}
          tooltip={tooltip}
          isMobile={isMobile}
          entityType={entityType}
          id={id}
          isProfile={isProfile}
        />
      ) : null}
      <div
        className={
          centered
            ? css`
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 90%;
                width: 100%;
              `
            : ''
        }
      >
        {ChartContent({ children, error, loading, noData, spinnerHeight, trackingData })}
      </div>
    </div>
  )
}

export default observer(ChartHolder)
