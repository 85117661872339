import { CustomView, CustomViewConfiguration } from '@pp/modules/analyse/common/custom-view/types/custom-view.type'
import { RootStoreInterface } from '@pp/store/root.store'
import { makeAutoObservable } from 'mobx'

export type CustomViewStoreInterface = {
  applicantViewNames: string[]
  applicantViews: CustomViewConfiguration[]
  attorneyViewNames: string[]
  attorneyViews: CustomViewConfiguration[]
  customView: CustomView | null
  lawFirmViewNames: string[]
  lawFirmViews: CustomViewConfiguration[]
  setCustomView: (customView: CustomView) => void
}

export class CustomViewStore implements CustomViewStoreInterface {
  private rootStore: RootStoreInterface
  customView: CustomView | null = null

  setCustomView = (customView: CustomView) => (this.customView = customView)

  get applicantViews() {
    return this.customView?.applicantConfiguration || []
  }

  get attorneyViews() {
    return this.customView?.attorneyConfiguration || []
  }

  get lawFirmViews() {
    return this.customView?.lawFirmConfiguration || []
  }

  get applicantViewNames() {
    return this.applicantViews.map((view) => view.customViewName)
  }

  get attorneyViewNames() {
    return this.attorneyViews.map((view) => view.customViewName)
  }

  get lawFirmViewNames() {
    return this.lawFirmViews.map((view) => view.customViewName)
  }

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }
}
