import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { FamilyType, ResultsGrouping } from '@pp/modules/analyse/common/types/app.type'
import * as routes from '@pp/routing/routes'
import { RootStoreInterface } from '@pp/store/root.store'
import * as _ from 'lodash'
import { makeAutoObservable } from 'mobx'
import * as R from 'ramda'

import { temporaryFilters } from './temporary-filters.const'
import { EntityFilter } from './typescript/filters.type'
import { Route } from '../../helpers/routing'

export type FilterType = { [key in EntityFilter]?: string[] | number[] }

export interface FilterStoreInterface {
  agentFilters: FilterType
  applicantFilters: FilterType
  attorneyFilters: FilterType
  dateFromDropdown: boolean
  familyType: FamilyType[]
  groupSearch: boolean
  isPersistedA: boolean
  isPersistedAt: boolean
  isPersistedDateType: boolean
  isPersistedLF: boolean
  negativeFilter: boolean
  persistedFiltersA: FilterType
  persistedFiltersAt: FilterType
  persistedFiltersLF: FilterType
}

const applicantGeoFilters = ['applicant_geo_bounds_high', 'applicant_geo_bounds_low', 'applicant_geo_bounds_distance']
const repGeoFilters = ['rep_geo_bounds_high', 'rep_geo_bounds_low', 'rep_geo_bounds_distance']
const geoFilters = [...applicantGeoFilters, ...repGeoFilters]

export class FilterStore implements FilterStoreInterface {
  private rootStore: RootStoreInterface
  initialFilters: FilterType = {
    // TODO
    date_from: [localStorage.date_from || '1980'],
    date_to: [localStorage.date_to || `${new Date().getFullYear()}`],
    date_type: [localStorage.date_type || DateType.publicationDate],
  }
  agentFilters: FilterType = this.initialFilters
  applicantFilters: FilterType = this.initialFilters
  attorneyFilters: FilterType = this.initialFilters
  persistedFiltersLF: FilterType = this.initialFilters
  persistedFiltersA: FilterType = this.initialFilters
  persistedFiltersAt: FilterType = this.initialFilters
  isPersistedLF = false
  isPersistedA = false
  isPersistedAt = false
  isPersistedDateType: boolean = localStorage.date_persisted === 'true' ? true : false
  isInitializedFromUrl = false
  dateFromDropdown = false
  negativeFilter = false

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this, {
      activeVisibleFilters: false,
      familyType: false,
      groupSearch: false,
      activeFilters: false,
    })
  }

  initializeFiltersFromUrl = (route: Route, queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)
    const queryFilters: FilterType = {}
    const metadataUrls: string[] = []

    for (const entry of urlSearchParams.entries()) {
      if (
        route.includes(routes.agentsRoute) ||
        route.includes(routes.applicantsRoute) ||
        route.includes(routes.attorneysRoute)
      ) {
        if (geoFilters.includes(entry[0])) {
          queryFilters[entry[0] as EntityFilter] = entry[1].split(',').map((filter) => Number(filter))
        } else {
          queryFilters[entry[0] as EntityFilter] = entry[1].split(',')
        }

        if (entry[0] === 'date_type') {
          this.setIsInitializedFromUrl(true)
        }

        if (entry[0] && entry[0].indexOf('_url') > -1) {
          const urlIdentifiers = entry[1].split(',')
          for (const urlIdentifier of urlIdentifiers) {
            metadataUrls.push(urlIdentifier.includes('!') ? urlIdentifier.slice(1) : urlIdentifier)
          }
        }

        if (entry[0] && entry[0].indexOf('family_type') > -1) {
          this.rootStore.applicationStore.setFamilyType(entry[1].split(',') as FamilyType[])
        }

        if (entry[0] && entry[0].indexOf('group_search') > -1) {
          const newValue: ResultsGrouping = entry[1] === 'true' ? 'group' : 'single'
          this.rootStore.applicationStore.setResultsGrouping(newValue)
        }
      }
    }

    metadataUrls.length && this.rootStore.applicationStore.setMetadataUrls(metadataUrls)

    const routeParts = route.split('/')
    const mainRoute = routeParts.length > 2 ? `/${routeParts[1]}` : route
    const entities = this.getEntitiesFromRoute(route)
    if (routes.agentsRoute.includes(mainRoute) && entities.length > 0) {
      this.rootStore.applicationStore.setAgentUrls(entities)
    } else if (routes.applicantsRoute.includes(mainRoute) && entities) {
      this.rootStore.applicationStore.setApplicantUrls(entities)
    } else if (routes.attorneysRoute.includes(mainRoute) && entities) {
      this.rootStore.applicationStore.setAttorneyUrls(entities)
    }

    switch (mainRoute) {
      case routes.agentsRoute:
        this.agentFilters = Object.assign({}, this.agentFilters, queryFilters)
        this.updateSearchParamsFromFilters(this.agentFilters)
        break
      case routes.applicantsRoute:
        this.applicantFilters = Object.assign({}, this.applicantFilters, queryFilters)
        this.updateSearchParamsFromFilters(this.applicantFilters)
        break
      case routes.attorneysRoute:
        this.attorneyFilters = Object.assign({}, this.attorneyFilters, queryFilters)
        this.updateSearchParamsFromFilters(this.attorneyFilters)
        break
    }
  }

  initializeFilters = (newFilters: FilterType, isPersisted: boolean) => {
    const route = this.rootStore.routerStore.location.pathname as Route
    const filters = Object.assign(isPersisted ? this.activeFilters : this.initialFilters, newFilters)

    this.setFiltersBasedOnRoute(route, filters)
    this.updateSearchParamsFromFilters(filters)
  }

  updateFilters = (newFilters: FilterType) => {
    const route = this.rootStore.routerStore.location.pathname as Route
    const filters = Object.assign(this.activeFilters, newFilters)

    this.setFiltersBasedOnRoute(route, filters)
    this.updateSearchParamsFromFilters(filters)
  }

  resetFilters = () => {
    const route = this.rootStore.routerStore.location.pathname as Route
    const newFilters = Object.assign({}, this.initialFilters, {
      date_from: this.activeFilters.date_from,
      date_to: this.activeFilters.date_to,
      date_type: this.activeFilters.date_type,
      family_type: this.activeFilters.family_type,
      group_search: this.activeFilters.group_search,
    })

    this.setFiltersBasedOnRoute(route, newFilters)
    this.updateSearchParamsFromFilters(newFilters)
  }

  removeFilter = (filterKey: EntityFilter, filterValue: string) => {
    const route = this.rootStore.routerStore.location.pathname as Route
    let newActiveFilters = Object.assign({}, this.activeFilters)
    if (filterKey === 'applicant_geo_bounds_name') {
      newActiveFilters = _.omit<FilterType>(this.activeFilters, [filterKey, ...applicantGeoFilters])
    } else if (filterKey === 'rep_geo_bounds_name') {
      newActiveFilters = _.omit<FilterType>(this.activeFilters, [filterKey, ...repGeoFilters])
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (this.activeFilters[filterKey] && this.activeFilters[filterKey].length > 1) {
        const values = [...(this.activeFilters[filterKey] as string[])]
        const index = values.indexOf(filterValue)
        if (index >= 0) {
          values.splice(index, 1)
          newActiveFilters = Object.assign({}, this.activeFilters, { [filterKey]: values })
        }
      } else newActiveFilters = R.dissoc<FilterType>(filterKey, this.activeFilters)
    }

    this.setFiltersBasedOnRoute(route, newActiveFilters)
    this.updateSearchParamsFromFilters(newActiveFilters)
  }

  setIsPersistedLF(isPersisted: boolean): void {
    this.isPersistedLF = isPersisted
    const route = this.rootStore.routerStore.location.pathname as Route

    if (this.isPersistedLF) {
      this.persistedFiltersLF = this.agentFilters
    } else {
      this.setFiltersBasedOnRoute(route, this.persistedFiltersLF)
    }
  }

  setIsPersistedA(isPersisted: boolean): void {
    this.isPersistedA = isPersisted
    const route = this.rootStore.routerStore.location.pathname as Route

    if (this.isPersistedA) {
      this.persistedFiltersA = this.applicantFilters
    } else {
      this.setFiltersBasedOnRoute(route, this.persistedFiltersA)
    }
  }

  setIsPersistedAt(isPersisted: boolean): void {
    this.isPersistedAt = isPersisted

    const route = this.rootStore.routerStore.location.pathname as Route

    if (this.isPersistedAt) {
      this.persistedFiltersAt = this.attorneyFilters
    } else {
      this.setFiltersBasedOnRoute(route, this.persistedFiltersAt)
    }
  }

  setIsPersistedDateType(isPersisted: boolean): void {
    this.isPersistedDateType = isPersisted
  }

  setIsInitializedFromUrl(isInitializedFromUrl: boolean): void {
    this.isInitializedFromUrl = isInitializedFromUrl
  }

  setDateFromDropdown(dateFromDropdown: boolean): void {
    this.dateFromDropdown = dateFromDropdown
  }

  setNegativeFilter(negativeFilter: boolean): void {
    this.negativeFilter = negativeFilter
  }

  get familyType(): FamilyType[] {
    return this.rootStore.applicationStore.familyType
  }

  get groupSearch(): boolean {
    return this.rootStore.applicationStore.resultsGrouping === 'group'
  }

  get activeVisibleFilters(): FilterType {
    const route = this.rootStore.routerStore.location.pathname as Route
    let visibleFilters = this.getFilterBasedOnRoute(route)

    // Remove hidden filter keys
    visibleFilters = R.pick(temporaryFilters, visibleFilters)

    return visibleFilters
  }

  get activeFilters(): FilterType {
    const route = this.rootStore.routerStore.location.pathname as Route
    return this.getFilterBasedOnRoute(route)
  }

  getActiveFiltersForUrl(): string {
    const searchStrings: string[] = []

    Object.entries(this.activeFilters).forEach(([key, value]) => {
      if (value && value.length > 0) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      }
    })

    return searchStrings.join('&')
  }

  private getFilterBasedOnRoute(route: Route) {
    const routeParts = route.split('/')
    const mainRoute = routeParts.length > 2 ? `/${routeParts[1]}` : route
    let filters: FilterType = {}

    switch (mainRoute) {
      case routes.agentsRoute:
        filters = {
          ...(this.isPersistedLF ? this.persistedFiltersLF : this.agentFilters),
          family_type: this.familyType,
          group_search: (this.groupSearch ? ['true'] : undefined) as unknown as string[],
        }
        break
      case routes.applicantsRoute:
        filters = {
          ...(this.isPersistedA ? this.persistedFiltersA : this.applicantFilters),
          family_type: this.familyType,
          group_search: (this.groupSearch ? ['true'] : undefined) as unknown as string[],
        }
        break
      case routes.attorneysRoute:
        filters = {
          ...(this.isPersistedAt ? this.persistedFiltersAt : this.attorneyFilters),
          family_type: this.familyType,
          group_search: (this.groupSearch ? ['true'] : undefined) as unknown as string[],
        }
        break
      default:
        filters = {
          ...this.initialFilters,
          family_type: this.familyType,
          group_search: (this.groupSearch ? ['true'] : undefined) as unknown as string[],
        }
    }

    return filters
  }

  private setFiltersBasedOnRoute(route: Route, filters: FilterType) {
    const routeParts = route.split('/')
    const mainRoute = routeParts.length > 2 ? `/${routeParts[1]}` : route

    switch (mainRoute) {
      case routes.agentsRoute:
        if (this.isPersistedLF) {
          this.persistedFiltersLF = filters
        } else {
          this.agentFilters = filters
        }
        break
      case routes.applicantsRoute:
        if (this.isPersistedA) {
          this.persistedFiltersA = filters
        } else {
          this.applicantFilters = filters
        }
        break
      case routes.attorneysRoute:
        if (this.isPersistedAt) {
          this.persistedFiltersAt = filters
        } else {
          this.attorneyFilters = filters
        }
        break
    }
  }

  private updateSearchParamsFromFilters(filters: FilterType) {
    const routerStore = this.rootStore.routerStore
    const routeParts = routerStore.location.pathname.split('/')
    const mainRoute = routeParts.length > 2 ? `/${routeParts[1]}` : routerStore.location.pathname
    const searchStrings: string[] = []

    Object.entries(filters).forEach(([key, value]) => {
      if (value && value.length > 0) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      }
    })

    if (
      [routes.agentsRoute, routes.applicantsRoute, routes.attorneysRoute].includes(mainRoute) &&
      this.rootStore.applicationStore.isAuthenticated
    ) {
      routerStore.replace(`${routerStore.location.pathname}?${searchStrings.join('&')}`)
    }
  }

  private getEntitiesFromRoute(route: Route): string[] {
    const urlSegments: string[] = route.split('/')
    const entities = urlSegments[2]

    return entities?.split(',') || []
  }
}
