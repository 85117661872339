import { FilterType } from '@pp/common/components/filters/filter.store'
import { BuilderPurpose } from '@pp/modules/analyse/common/types/app.type'

export const mapBuilderPurpose = (activeFilters: FilterType, chartType?: string) => {
  if (!chartType) return activeFilters
  const mappedActiveFilters: FilterType & { builder_purpose?: BuilderPurpose } = { ...activeFilters }
  if (chartType.includes('Exchange')) mappedActiveFilters.builder_purpose = BuilderPurpose.CASE_EXCHANGE
  if (chartType.includes('Validation')) mappedActiveFilters.builder_purpose = BuilderPurpose.VALIDATION

  return mappedActiveFilters
}
