import React, { ReactNode } from 'react'

import { ApolloError } from '@apollo/client'
import { css } from '@emotion/css'
import DefaultFailure from '@pp/common/components/default-failure/DefaultFailure'
import NoData from '@pp/common/components/no-data/NoData'
import Spinner from '@pp/common/components/spinner/Spinner'

export const chartCardCss = (containerHeight: string | undefined = '250px') => css`
  min-height: ${containerHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
`

type TrackingData = {
  entities: string[]
  measureMount: boolean
  measureUnMount: boolean
  page: string | undefined
  title: string
}

type Props = {
  children: ReactNode
  error?: null | ApolloError
  loading?: boolean
  noData?: boolean | null
  spinnerHeight?: string
  trackingData: TrackingData
}

const ChartContent = ({ children, error, loading, noData, spinnerHeight, trackingData }: Props) => {
  if (error) return <DefaultFailure containerHeight={spinnerHeight} error={error} />
  if (loading) return <Spinner spinnerHeight={spinnerHeight} trackingData={trackingData} />
  if (noData)
    return (
      <div className={chartCardCss(spinnerHeight)}>
        <NoData containerHeight={spinnerHeight} />
      </div>
    )
  return children
}

export default ChartContent
