import { ReactNode } from 'react'

import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { FilterType } from '@pp/common/components/filters/filter.store'
import { ChartWidgetType } from '@pp/common/typescript/dashboard.type'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { BuilderPurpose, FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import _ from 'lodash'
import { makeAutoObservable } from 'mobx'

import { FilingsQueryState, LocationQueryState } from './constants/query-state-machine-filings.const'
import {
  ParsedResult,
  FLimitingClauseType,
  Result,
  SearchParameters,
  RadiusValue,
  RadiusUnit,
  SearchParametersProperty,
} from '../../typescript/search.interface'

export type NumberedResult = ParsedResult

export enum Route {
  DIR = 'DIR',
  PCT = 'PCT',
}

export enum GrantStatus {
  GRANTED = 'GRANTED',
  NOT_GRANTED = 'NOT_GRANTED',
}

export enum DecisionMaker {
  A = 'A',
  LF = 'LF',
}

enum Direction {
  Received = 'RECEIVED',
  Sent = 'SENT',
}

export type SearchFilter = FilterType & {
  [key: string]:
    | string[]
    | FamilyType[]
    | ['LF' | 'A']
    | number[]
    | number[]
    | string[]
    | boolean[]
    | undefined
    | ChartWidgetType[]
  advanced_search_type: ['LF' | 'A']
  builder_purpose: [BuilderPurpose]
  confirmed?: boolean[]
  family_type: FamilyType[]
  relevant_count_from?: number[]
  relevant_count_to?: number[]
  selected_charts?: ChartWidgetType[]
}

export interface FilingsSearchStoreInterface {
  activePage: number
  familyType: FamilyType[]
  limitingClauses: FLimitingClauseType[]
  locationQueryState: LocationQueryState
  queryState: FilingsQueryState
  results: Result[] | null
  searchParameters: SearchParameters
}

const initialSearchParameters: SearchParameters = {
  entityGeoBoundsName: 'Washington, D.C.',
  entityGeoBoundsHigh: [38.9958641, -76.909393],
  entityGeoBoundsLow: [38.7916449, -77.119759],
  entityGeoBoundsRadius: RadiusValue._0,
  entityGeoBoundsRadiusUnit: RadiusUnit.MI,
  entityCountry: [],
  primaryAttributeAuthority: [],
  primaryAttributeCountry: [],
  primaryAttributeGeoBoundsName: 'Washington, D.C.',
  primaryAttributeGeoBoundsHigh: [38.9958641, -76.909393],
  primaryAttributeGeoBoundsLow: [38.7916449, -77.119759],
  primaryAttributeGeoBoundsRadius: RadiusValue._0,
  primaryAttributeGeoBoundsRadiusUnit: RadiusUnit.MI,
  date_type: 'm',
  date_from: new Date().getFullYear() - 3,
  date_to: new Date().getFullYear(),
  filingReceivedFromCountry: [],
  filingReceivedFromPublAuth: [],
  filingReceivedFromGeoBoundsName: 'Washington, D.C.',
  filingReceivedFromGeoBoundsHigh: [38.9958641, -76.909393],
  filingReceivedFromGeoBoundsLow: [38.7916449, -77.119759],
  filingReceivedFromGeoBoundsRadius: RadiusValue._0,
  filingReceivedFromGeoBoundsRadiusUnit: RadiusUnit.MI,
  filingSentToCountry: [],
  filingSentToPublnAuth: [],
  filingSentToGeoBoundsName: 'Washington, D.C.',
  filingSentToGeoBoundsHigh: [38.9958641, -76.909393],
  filingSentToGeoBoundsLow: [38.7916449, -77.119759],
  filingSentToGeoBoundsRadius: RadiusValue._0,
  filingSentToGeoBoundsRadiusUnit: RadiusUnit.MI,
  receivedCasesApplicantDecision: true,
  sentCasesApplicantDecision: true,
  secondLevelCasesApplicantDecision: true,
  representative_type: [],
  appln_type: [],
  rep_location: [],
  nice_class: [],
  lifecycle: [],
  nice_category: [],
  verbal_element: [],
  ipc_class: [],
  techn_field_nr: [],
  sequence: [],
  grant_status: [],
  route: [],
  legal_state: [],
  relevant_count_from: [],
  relevant_count_to: [],
  applicant_country: [],
  direction: [],
  applicant_origin: [],
  applicant_url: [],
  applicant_group_url: [],
  rep_url: [],
  rep_country: [],
  publn_auth: [],
  int_appln_auth: [],
  int_decision_maker: [],
  dom_publn_auth: [],
  dom_rep_country: [],
  dom_rep_url: [],
  int_publn_auth: [],
  int_rep_country: [],
  int_rep_url: [],
  foreign_rep_url: [],
  foreign_appln_auth: [],
  foreign_publn_auth: [],
  foreign_rep_country: [],
}

const newFilters = [
  { prop: 'direction', value: FLimitingClauseType.Direction },
  { prop: 'applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'sequence', value: FLimitingClauseType.Sequence },
  { prop: 'legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'route', value: FLimitingClauseType.Route },
  { prop: 'appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'verbal_element', value: FLimitingClauseType.VerbalElement },
  { prop: 'lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'applicant_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'applicant_group_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'foreign_appln_auth', value: FLimitingClauseType.ForeignApplnAuth },
  { prop: 'foreign_publn_auth', value: FLimitingClauseType.ForeignPublnAuth },
  { prop: 'foreign_rep_country', value: FLimitingClauseType.ForeignRepCountry },
  { prop: 'rep_url', value: FLimitingClauseType.RepUrl },
  { prop: 'representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'publn_auth', value: FLimitingClauseType.PublnAuth },
  { prop: 'techn_field_nr', value: FLimitingClauseType.TechnField },
  { prop: 'rep_url', value: FLimitingClauseType.RepUrl },
  { prop: 'rep_country', value: FLimitingClauseType.RepCountry },
]

export class FilingsSearchStore implements FilingsSearchStoreInterface {
  private rootStore: RootStoreInterface

  results: NumberedResult[] | null = null
  activePage = 1
  locationQueryState = LocationQueryState.FromCountries
  queryState = FilingsQueryState.LawfirmsFileatPublnauth
  limitingClauses: FLimitingClauseType[] = []
  searchParameters: SearchParameters = initialSearchParameters
  selectedCharts: ChartWidgetType[] = []
  confirmed: boolean[] = [true, false]
  visibleConstraints: ReactNode[] = []
  filingUrlIdentifiers: string[] = []

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  setQueryState = (queryState: FilingsQueryState) => {
    this.limitingClauses = []
    this.searchParameters = {
      ...initialSearchParameters,
      date_from: this.searchParameters.date_from,
      date_to: this.searchParameters.date_to,
      date_type: queryState.indexOf('fileat') > 0 ? DateType.publicationDate : DateType.transferDate,
      entityCountry: this.searchParameters.entityCountry,
      entityGeoBoundsName: this.searchParameters.entityGeoBoundsName,
      entityGeoBoundsHigh: this.searchParameters.entityGeoBoundsHigh,
      entityGeoBoundsLow: this.searchParameters.entityGeoBoundsLow,
      entityGeoBoundsRadius: this.searchParameters.entityGeoBoundsRadius,
      entityGeoBoundsRadiusUnit: this.searchParameters.entityGeoBoundsRadiusUnit,
    }
    this.queryState = queryState
  }

  setLocationQueryState = (locationQueryState: LocationQueryState) => {
    this.locationQueryState = locationQueryState
  }

  setVisibleConstraints = (visibleConstraint: ReactNode) => {
    this.visibleConstraints = [...this.visibleConstraints, visibleConstraint]
  }

  setSearchParameter = (searchParameter: {}) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: SearchParametersProperty) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter]
  }

  toggleLimitingClause = (clause: FLimitingClauseType) => {
    const index = this.limitingClauses.indexOf(clause)
    if (index !== -1) {
      this.limitingClauses.splice(index, 1)
    } else {
      this.limitingClauses.push(clause)
    }
  }

  setLimitingClauses = (clauses: FLimitingClauseType[]) => {
    this.limitingClauses = clauses
  }

  setResults = (results: Result[] | null) => {
    if (results) {
      this.results = results.map((result: Result, id) => ({
        id: this.leftPad(id + 1, results.length.toString().length),
        ...result,
      }))
    } else this.results = results
  }

  setFilingUrlIdentifiers = (filingUrlIdentifiers: string[]) => {
    this.filingUrlIdentifiers = filingUrlIdentifiers
  }

  setActivePage = (activePage: number) => {
    this.activePage = activePage
  }

  setCharts = (charts: ChartWidgetType[]) => {
    this.selectedCharts = charts
  }

  resetSearchUI = () => {
    this.results = null
    this.activePage = 1
    this.locationQueryState = LocationQueryState.FromCountries
    this.queryState = FilingsQueryState.LawfirmsFileatPublnauth
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters
    this.selectedCharts = []

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  get isDefaultState() {
    return JSON.stringify(this.searchParameters) === JSON.stringify(initialSearchParameters)
  }

  get familyType(): FamilyType[] {
    return this.rootStore.applicationStore.familyType
  }

  get getCharts() {
    return this.selectedCharts
  }

  get getSearchDates() {
    return {
      date_from: this.searchParameters.date_from.toString(),
      date_to: this.searchParameters.date_to.toString(),
    }
  }

  get serverFilters() {
    const entityType = this.queryState.startsWith('applicants') ? EntityType.Applicant : EntityType.Agent
    const builderPurpose =
      this.queryState.indexOf('fileat') > 0 ? BuilderPurpose.OVERVIEW : BuilderPurpose.CASE_EXCHANGE

    const filter: SearchFilter = {
      advanced_search_type: [entityType === EntityType.Agent ? 'LF' : 'A'],
      family_type: this.familyType,
      builder_purpose: [builderPurpose],
      date_from: [this.searchParameters.date_from.toString()],
      date_to: [this.searchParameters.date_to.toString()],
      date_type: [this.searchParameters.date_type.toUpperCase() as DateType],
    }

    newFilters.forEach((el) => (filter[el.prop] = this.searchParameters[el.prop]))

    // Iterating over main query states and constructing filters
    switch (this.queryState) {
      case FilingsQueryState.ApplicantsFileatPublnAuth:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (this.searchParameters.entityCountry.length > 0)
            filter.applicant_country = this.searchParameters.entityCountry
        } else {
          filter.applicant_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.applicant_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.applicant_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.applicant_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.applicant_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }

        if (this.searchParameters.primaryAttributeAuthority.length > 0)
          filter.publn_auth = this.searchParameters.primaryAttributeAuthority

        // International representative country
        filter.int_rep_country = this.searchParameters.int_rep_country

        // Domestic representative country
        filter.dom_rep_country = this.searchParameters.dom_rep_country

        // International publication authority
        filter.int_publn_auth = this.searchParameters.int_publn_auth

        // Domestic publication authority
        filter.dom_publn_auth = this.searchParameters.dom_publn_auth

        break
      case FilingsQueryState.LawfirmsFileatPublnauth:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (this.searchParameters.entityCountry.length > 0) filter.rep_country = this.searchParameters.entityCountry
        } else {
          filter.rep_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.rep_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.rep_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.rep_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.rep_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }

        if (this.searchParameters.primaryAttributeAuthority.length > 0)
          filter.publn_auth = this.searchParameters.primaryAttributeAuthority

        break
      case FilingsQueryState.LawfirmsReceivefromCountry:
        filter.direction = [Direction.Received]
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (this.searchParameters.entityCountry.length > 0) filter.rep_country = this.searchParameters.entityCountry
        } else {
          filter.rep_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.rep_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.rep_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.rep_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.rep_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }
        if (this.searchParameters.primaryAttributeCountry.length > 0)
          filter.foreign_rep_country = this.searchParameters.primaryAttributeCountry
        if (this.searchParameters.filingReceivedFromPublAuth.length > 0)
          filter.foreign_publn_auth = this.searchParameters.filingReceivedFromPublAuth

        break
      case FilingsQueryState.LawfirmsReceivefromGeolocation:
        filter.direction = [Direction.Received]
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (this.searchParameters.entityCountry.length > 0) filter.rep_country = this.searchParameters.entityCountry
        } else {
          filter.rep_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.rep_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.rep_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.rep_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.rep_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }
        filter.foreign_rep_geo_bounds_name = [this.searchParameters.primaryAttributeGeoBoundsName]
        filter.foreign_rep_geo_bounds_high = this.searchParameters.primaryAttributeGeoBoundsHigh
        filter.foreign_rep_geo_bounds_low = this.searchParameters.primaryAttributeGeoBoundsLow
        filter.foreign_rep_geo_bounds_distance = [this.searchParameters.primaryAttributeGeoBoundsRadius]
        filter.foreign_rep_geo_bounds_distance_unit = [this.searchParameters.primaryAttributeGeoBoundsRadiusUnit]

        break
      case FilingsQueryState.LawfirmsReceivefromPublnauth:
        filter.direction = [Direction.Received]
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (this.searchParameters.entityCountry.length > 0) filter.rep_country = this.searchParameters.entityCountry
        } else {
          filter.rep_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.rep_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.rep_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.rep_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.rep_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }
        if (this.searchParameters.primaryAttributeAuthority.length > 0)
          filter.foreign_publn_auth = this.searchParameters.primaryAttributeAuthority
        // Received from Law Firms in
        if (this.searchParameters.primaryAttributeCountry.length > 0)
          filter.foreign_rep_country = this.searchParameters.primaryAttributeCountry
        // Received from Law Firms around
        if (
          this.searchParameters.filingReceivedFromLocationLat !== initialSearchParameters.filingReceivedFromLocationLat
        ) {
          filter.foreign_rep_geo_bounds_name = [this.searchParameters.filingReceivedFromGeoBoundsName]
          filter.foreign_rep_geo_bounds_high = this.searchParameters.filingReceivedFromGeoBoundsHigh
          filter.foreign_rep_geo_bounds_low = this.searchParameters.filingReceivedFromGeoBoundsLow
          filter.foreign_rep_geo_bounds_distance = [this.searchParameters.filingReceivedFromGeoBoundsRadius]
          filter.foreign_rep_geo_bounds_distance_unit = [this.searchParameters.filingReceivedFromGeoBoundsRadiusUnit]
        }

        break
      case FilingsQueryState.LawfirmsSendtoCountry:
        filter.direction = [Direction.Sent]
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          filter.rep_country = this.searchParameters.entityCountry
        } else {
          filter.rep_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.rep_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.rep_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.rep_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.rep_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }
        filter.foreign_rep_country = this.searchParameters.primaryAttributeCountry
        filter.foreign_publn_auth = this.searchParameters.filingSentToPublnAuth

        break
      case FilingsQueryState.LawfirmsSendtoGeolocation:
        filter.direction = [Direction.Sent]
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (this.searchParameters.entityCountry.length > 0) filter.rep_country = this.searchParameters.entityCountry
        } else {
          filter.rep_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.rep_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.rep_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.rep_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.rep_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }
        filter.foreign_rep_geo_bounds_name = [this.searchParameters.primaryAttributeGeoBoundsName]
        filter.foreign_rep_geo_bounds_high = this.searchParameters.primaryAttributeGeoBoundsHigh
        filter.foreign_rep_geo_bounds_low = this.searchParameters.primaryAttributeGeoBoundsLow
        filter.foreign_rep_geo_bounds_distance = [this.searchParameters.primaryAttributeGeoBoundsRadius]
        filter.foreign_rep_geo_bounds_distance_unit = [this.searchParameters.primaryAttributeGeoBoundsRadiusUnit]

        break
      // To be added!
      case FilingsQueryState.LawfirmsSendtoPublnauth:
        filter.direction = [Direction.Sent]
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          filter.rep_country = this.searchParameters.entityCountry
        } else {
          filter.rep_geo_bounds_name = [this.searchParameters.entityGeoBoundsName]
          filter.rep_geo_bounds_high = this.searchParameters.entityGeoBoundsHigh
          filter.rep_geo_bounds_low = this.searchParameters.entityGeoBoundsLow
          filter.rep_geo_bounds_distance = [this.searchParameters.entityGeoBoundsRadius]
          filter.rep_geo_bounds_distance_unit = [this.searchParameters.entityGeoBoundsRadiusUnit]
        }
        if (this.searchParameters.primaryAttributeAuthority.length > 0)
          filter.foreign_publn_auth = this.searchParameters.primaryAttributeAuthority
        // Sent to Law Firms in
        if (this.searchParameters.filingSentToCountry.length > 0)
          filter.foreign_rep_country = this.searchParameters.filingSentToCountry
        // Received from Law Firms around
        if (this.searchParameters.filingSentToLocationLat !== initialSearchParameters.filingSentToLocationLat) {
          filter.foreign_rep_geo_bounds_name = [this.searchParameters.filingSentToGeoBoundsName]
          filter.foreign_rep_geo_bounds_high = this.searchParameters.filingSentToGeoBoundsHigh
          filter.foreign_rep_geo_bounds_low = this.searchParameters.filingSentToGeoBoundsLow
          filter.foreign_rep_geo_bounds_distance = [this.searchParameters.filingSentToGeoBoundsRadius]
          filter.foreign_rep_geo_bounds_distance_unit = [this.searchParameters.filingSentToGeoBoundsRadiusUnit]
        }

        break
    }

    // Iterating over constraints and constructing additional filters

    // Selected Charts
    filter.selected_charts = this.selectedCharts

    // IPCs
    filter.ipc_1 = [] as string[]
    filter.ipc_3 = [] as string[]
    filter.ipc_4 = [] as string[]
    filter.ipc_8 = [] as string[]
    for (const entry of this.searchParameters.ipc_class) {
      if (entry?.length === 1 || entry?.length === 2) {
        filter.ipc_1.push(entry)
      } else if (entry?.length === 3 || (entry.includes('!') && entry?.length === 4)) {
        filter.ipc_3.push(entry)
      } else if (entry?.length === 4 || entry?.length === 5) {
        filter.ipc_4.push(entry)
      } else {
        filter.ipc_8.push(entry)
      }
    }

    // Technical field
    if (this.limitingClauses.includes(FLimitingClauseType.TechnField)) {
      let technicalFields: string[] = []
      for (const element of this.searchParameters.techn_field_nr) {
        if (Number.isNaN(Number(element))) {
          const elements = element.split(',')
          if (element.includes('!')) {
            technicalFields = [...technicalFields, ...elements.map((entry, index) => (index ? `!${entry}` : entry))]
          } else {
            technicalFields = [...technicalFields, ...elements]
          }
        } else {
          technicalFields.push(element)
        }
      }
      filter.techn_field_nr = technicalFields
    }

    // Relevant Count filter
    if (
      this.limitingClauses.includes(FLimitingClauseType.RelevantCountFrom) ||
      this.limitingClauses.includes(FLimitingClauseType.CaseRelevantCountFrom)
    ) {
      filter.relevant_count_from = this.searchParameters.relevant_count_from?.map((entry) => Number(entry))
      filter.relevant_count_to = this.searchParameters.relevant_count_to?.map((entry) => Number(entry))
    }

    return _.omitBy(filter, _.isEmpty)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)
    const selectedCharts = urlSearchParams.get('selected_charts')
    const splittedCharts = selectedCharts && selectedCharts.split(',')
    const confirmed = urlSearchParams
      .get('confirmed')
      ?.split(',')
      .map((el) => {
        if (el === 'true') return true
        if (el === 'false') return false
        return el
      })

    if (urlSearchParams.get('family_type')) {
      this.rootStore.applicationStore.setFamilyType(
        (this.arrayFromString(urlSearchParams.get('family_type')) as FamilyType[]) || 'P',
      )
    }

    if (confirmed) this.confirmed = confirmed as boolean[]
    if (selectedCharts) this.selectedCharts = splittedCharts as ChartWidgetType[]
    else this.selectedCharts = []

    const queryState = urlSearchParams.get('queryState') as FilingsQueryState
    if (queryState) this.queryState = queryState

    const locationQueryState = urlSearchParams.get('locationQueryState') as LocationQueryState
    if (locationQueryState) this.setLocationQueryState(locationQueryState)

    const limitingClauses = urlSearchParams.get('limitingClauses')
    const splitLimitingClauses = limitingClauses ? limitingClauses.split(',') : []
    this.limitingClauses = splitLimitingClauses as FLimitingClauseType[]

    this.searchParameters.date_from = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.date_from

    this.searchParameters.date_to = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.date_from

    this.searchParameters.date_type = urlSearchParams.get('date_type') || initialSearchParameters.date_type

    switch (queryState) {
      case FilingsQueryState.ApplicantsFileatPublnAuth:
        if (!splitLimitingClauses?.includes('publn_auth')) {
          this.searchParameters.primaryAttributeAuthority =
            this.arrayFromString(urlSearchParams.get('publn_auth')) || initialSearchParameters.primaryAttributeAuthority
        }

        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (!splitLimitingClauses?.includes('applicant_country')) {
            this.searchParameters.entityCountry =
              this.arrayFromString(urlSearchParams.get('applicant_country')) || initialSearchParameters.entityCountry
          }
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('applicant_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('applicant_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('applicant_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('applicant_geo_bounds_distance')) ||
            initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('applicant_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }
        break
      case FilingsQueryState.LawfirmsFileatPublnauth:
        if (!splitLimitingClauses?.includes('publn_auth')) {
          this.searchParameters.primaryAttributeAuthority =
            this.arrayFromString(urlSearchParams.get('publn_auth')) || initialSearchParameters.primaryAttributeAuthority
        }

        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (!splitLimitingClauses?.includes('rep_country')) {
            this.searchParameters.entityCountry =
              this.arrayFromString(urlSearchParams.get('rep_country')) || initialSearchParameters.entityCountry
          }
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('rep_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('rep_geo_bounds_distance')) || initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('rep_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }
        break
      case FilingsQueryState.LawfirmsReceivefromCountry:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (!splitLimitingClauses?.includes('rep_country')) {
            this.searchParameters.entityCountry =
              this.arrayFromString(urlSearchParams.get('rep_country')) || initialSearchParameters.entityCountry
          }
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('rep_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('rep_geo_bounds_distance')) || initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('rep_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }

        if (!splitLimitingClauses?.includes('foreign_rep_country')) {
          this.searchParameters.primaryAttributeCountry =
            this.arrayFromString(urlSearchParams.get('foreign_rep_country')) ||
            initialSearchParameters.primaryAttributeCountry
        }

        break
      case FilingsQueryState.LawfirmsReceivefromGeolocation:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (!splitLimitingClauses?.includes('rep_country')) {
            this.searchParameters.entityCountry =
              this.arrayFromString(urlSearchParams.get('rep_country')) || initialSearchParameters.entityCountry
          }
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('rep_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('rep_geo_bounds_distance')) || initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('rep_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }

        this.searchParameters.primaryAttributeGeoBoundsName =
          urlSearchParams.get('foreign_rep_geo_bounds_name') || initialSearchParameters.primaryAttributeGeoBoundsName
        this.searchParameters.primaryAttributeGeoBoundsHigh =
          this.arrayFromString(urlSearchParams.get('foreign_rep_geo_bounds_high')).map((el) => Number(el)) ||
          initialSearchParameters.primaryAttributeGeoBoundsHigh
        this.searchParameters.primaryAttributeGeoBoundsLow =
          this.arrayFromString(urlSearchParams.get('foreign_rep_geo_bounds_low')).map((el) => Number(el)) ||
          initialSearchParameters.primaryAttributeGeoBoundsLow
        this.searchParameters.primaryAttributeGeoBoundsRadius =
          Number(urlSearchParams.get('foreign_rep_geo_bounds_distance')) ||
          initialSearchParameters.primaryAttributeGeoBoundsRadius
        this.searchParameters.primaryAttributeLocationRadiusUnit =
          (urlSearchParams.get('foreign_rep_geo_bounds_distance_unit') as RadiusUnit) ||
          initialSearchParameters.primaryAttributeLocationRadiusUnit
        break
      case FilingsQueryState.LawfirmsReceivefromPublnauth:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          if (!splitLimitingClauses?.includes('rep_country')) {
            this.searchParameters.entityCountry =
              this.arrayFromString(urlSearchParams.get('rep_country')) || initialSearchParameters.entityCountry
          }
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('rep_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('rep_geo_bounds_distance')) || initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('rep_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }

        if (!splitLimitingClauses?.includes('foreign_publn_auth')) {
          this.searchParameters.primaryAttributeAuthority =
            this.arrayFromString(urlSearchParams.get('foreign_publn_auth')) ||
            initialSearchParameters.primaryAttributeAuthority
        }
        break
      case FilingsQueryState.LawfirmsSendtoCountry:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('rep_country')) || initialSearchParameters.entityCountry
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('rep_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('rep_geo_bounds_distance')) || initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('rep_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }

        if (!splitLimitingClauses?.includes('foreign_rep_country')) {
          this.searchParameters.primaryAttributeCountry =
            this.arrayFromString(urlSearchParams.get('foreign_rep_country')) ||
            initialSearchParameters.primaryAttributeCountry
        }
        break
      case FilingsQueryState.LawfirmsSendtoGeolocation:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('rep_country')) || initialSearchParameters.entityCountry
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('rep_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('rep_geo_bounds_distance')) || initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('rep_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }

        this.searchParameters.primaryAttributeGeoBoundsName =
          urlSearchParams.get('foreign_rep_geo_bounds_name') || initialSearchParameters.primaryAttributeGeoBoundsName
        this.searchParameters.primaryAttributeGeoBoundsHigh =
          this.arrayFromString(urlSearchParams.get('foreign_rep_geo_bounds_high')).map((el) => Number(el)) ||
          initialSearchParameters.primaryAttributeGeoBoundsHigh
        this.searchParameters.primaryAttributeGeoBoundsLow =
          this.arrayFromString(urlSearchParams.get('foreign_rep_geo_bounds_low')).map((el) => Number(el)) ||
          initialSearchParameters.primaryAttributeGeoBoundsLow
        this.searchParameters.primaryAttributeGeoBoundsRadius =
          Number(urlSearchParams.get('foreign_rep_geo_bounds_distance')) ||
          initialSearchParameters.primaryAttributeGeoBoundsRadius
        this.searchParameters.primaryAttributeLocationRadiusUnit =
          (urlSearchParams.get('foreign_rep_geo_bounds_distance_unit') as RadiusUnit) ||
          initialSearchParameters.primaryAttributeLocationRadiusUnit
        break
      case FilingsQueryState.LawfirmsSendtoPublnauth:
        if (this.locationQueryState === LocationQueryState.FromCountries) {
          this.searchParameters.entityCountry =
            this.arrayFromString(urlSearchParams.get('rep_country')) || initialSearchParameters.entityCountry
        } else {
          this.searchParameters.entityGeoBoundsName =
            urlSearchParams.get('rep_geo_bounds_name') || initialSearchParameters.entityGeoBoundsName
          this.searchParameters.entityGeoBoundsHigh =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_high')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsHigh
          this.searchParameters.entityGeoBoundsLow =
            this.arrayFromString(urlSearchParams.get('rep_geo_bounds_low')).map((el) => Number(el)) ||
            initialSearchParameters.entityGeoBoundsLow
          this.searchParameters.entityGeoBoundsRadius =
            Number(urlSearchParams.get('rep_geo_bounds_distance')) || initialSearchParameters.entityGeoBoundsRadius
          this.searchParameters.entityGeoBoundsRadiusUnit =
            (urlSearchParams.get('rep_geo_bounds_distance_unit') as RadiusUnit) ||
            initialSearchParameters.entityGeoBoundsRadiusUnit
        }

        if (!splitLimitingClauses?.includes('foreign_publn_auth')) {
          this.searchParameters.primaryAttributeAuthority =
            this.arrayFromString(urlSearchParams.get('foreign_publn_auth')) ||
            initialSearchParameters.primaryAttributeAuthority
        }
        break
    }

    // IPCs
    if (this.limitingClauses.includes(FLimitingClauseType.IPC)) {
      const ipc1 = this.arrayFromString(urlSearchParams.get('ipc_1'))
      const ipc3 = this.arrayFromString(urlSearchParams.get('ipc_3'))
      const ipc4 = this.arrayFromString(urlSearchParams.get('ipc_4'))
      const ipc8 = this.arrayFromString(urlSearchParams.get('ipc_8'))

      this.searchParameters.ipc_class = [...ipc1, ...ipc3, ...ipc4, ...ipc8]
    }

    newFilters.forEach((el) => {
      if (this.limitingClauses.includes(el.value)) {
        this.searchParameters[el.prop] =
          this.arrayFromString(urlSearchParams.get(el.prop)) || initialSearchParameters[el.prop]
      }
    })

    // Relevant Count
    if (
      this.limitingClauses.includes(FLimitingClauseType.RelevantCountFrom) ||
      this.limitingClauses.includes(FLimitingClauseType.CaseRelevantCountFrom)
    ) {
      this.searchParameters.relevant_count_from =
        this.arrayFromString(urlSearchParams.get('relevant_count_from'))?.map((entry) => Number(entry)) ||
        initialSearchParameters.relevant_count_from
      this.searchParameters.relevant_count_to =
        this.arrayFromString(urlSearchParams.get('relevant_count_to'))?.map((entry) => Number(entry)) ||
        initialSearchParameters.relevant_count_to
    }
  }

  updateSearchParamsFromFilters = (filters: SearchFilter) => {
    const searchStrings: string[] = []
    let reducedFilters: Omit<SearchFilter, 'advanced_search_type' | 'builder_purpose' | 'family_type'> = Object.assign(
      {},
      filters,
      { advanced_search_type: undefined, BuilderPurpose: undefined },
    )
    reducedFilters = _.omitBy(reducedFilters, _.isNil)

    searchStrings.push(`queryState=${this.queryState}`)
    searchStrings.push(`locationQueryState=${this.locationQueryState}`)

    if (this.limitingClauses.length > 0) searchStrings.push(`limitingClauses=${this.limitingClauses}`)

    Object.entries(reducedFilters).forEach(([key, value]) => {
      if (Array.isArray(value)) searchStrings.push(`${key}=${value && value.join(',')}`)
      else searchStrings.push(`${key}=${value}`)
    })

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?${searchStrings.join('&')}`)
  }

  getSearchParamsFromFilters() {
    const searchStrings: string[] = []

    Object.entries(this.serverFilters).forEach(([key, value]) => {
      if (
        key !== 'advanced_search_type' &&
        key !== 'builder_purpose' &&
        key !== 'int_rep_geo_bounds_name' &&
        key !== 'int_rep_geo_bounds_low' &&
        key !== 'int_rep_geo_bounds_high' &&
        key !== 'int_rep_geo_bounds_distance' &&
        key !== 'int_rep_geo_bounds_distance_unit' &&
        key !== 'dom_rep_geo_bounds_name' &&
        key !== 'dom_rep_geo_bounds_low' &&
        key !== 'dom_rep_geo_bounds_high' &&
        key !== 'dom_rep_geo_bounds_distance' &&
        key !== 'dom_rep_geo_bounds_distance_unit' &&
        key !== 'foreign_rep_geo_bounds_name' &&
        key !== 'foreign_rep_geo_bounds_low' &&
        key !== 'foreign_rep_geo_bounds_high' &&
        key !== 'foreign_rep_geo_bounds_distance' &&
        key !== 'foreign_rep_geo_bounds_distance_unit' &&
        key !== 'selected_charts'
      ) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      }
    })

    return searchStrings.join('&')
  }

  private arrayFromString(str: string | null): string[] {
    if (str) {
      const splitStr = str.split(',')
      return splitStr
    } else {
      return []
    }
  }

  private leftPad(no: number, targetLength: number) {
    let output = no + ''
    while (output.length < targetLength) {
      output = '0' + output
    }
    return output
  }
}
