import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { useStores } from '@pp/store/useStore.hook'
import _ from 'lodash'

import { prepareFilters } from '../../helpers'

type Props = { chartType?: string }

export const useIsChartSaved = ({ chartType }: Props) => {
  const { applicationStore, filterStore } = useStores()
  const entityType = chartType?.startsWith('lf') ? EntityType.Agent : EntityType.Applicant
  const entities = entityType === EntityType.Agent ? applicationStore.agentUrls : applicationStore.applicantUrls
  const chartFilter = prepareFilters(filterStore.activeFilters, entityType, applicationStore.metadata, entities)
  const comparisonChartFilter = JSON.stringify(_.omit(chartFilter, 'metadata'))
  const savedCharts = applicationStore.chartConfigurations

  let isChartSaved = false

  if (savedCharts.length > 0) {
    isChartSaved = !!savedCharts.find((savedChart) => {
      const savedChartObject = JSON.parse(savedChart.chartFilter)
      const comparisonChartObject = JSON.stringify(_.omit(savedChartObject, 'metadata'))
      return comparisonChartObject === comparisonChartFilter && savedChart.chartType === chartType
    })
  }

  return { isChartSaved, entities, entityType }
}
