import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import Shevron from '@pp/assets/icons/shevron-icon.svg'
import ToolsIcon from '@pp/assets/icons/tools-icon.svg'
import { newLabel } from '@pp/common/components/navigation/sidebar/components/sidebar-menus/insights-menu/InsightsMenu'
import { toolsRoutes } from '@pp/common/components/navigation/sidebar/route-groups'
import { iconCss, menuHeaderCss } from '@pp/common/components/navigation/sidebar/sidebar.css'
import { Menu, Image } from 'semantic-ui-react'

import ToolsItems from './ToolsItems'
import { MenuProps } from '../analyze-menu/AnalyzeMenu'

const ToolsMenu = ({
  active,
  minimizedSideBar,
  menus,
  setShowMenus,
  isMobile,
  toggleSidebar,
  onNavLinkClick,
  isSmallHeight,
}: MenuProps) => {
  const handleClick = () => {
    if (minimizedSideBar) {
      toggleSidebar && toggleSidebar()
    } else {
      if (isMobile || isSmallHeight) {
        setShowMenus((prevState) => ({
          analyzeMenu: false,
          searchMenu: false,
          checkMenu: false,
          resourceSubmenu: false,
          toolsMenu: !prevState.toolsMenu,
        }))
      } else {
        setShowMenus((prevState) => ({
          analyzeMenu: prevState.analyzeMenu,
          searchMenu: prevState.searchMenu,
          checkMenu: prevState.checkMenu,
          resourceSubmenu: prevState.resourceSubmenu,
          toolsMenu: !prevState.toolsMenu,
        }))
      }
    }
  }

  return (
    <>
      <Menu.Header
        className={cx(
          menuHeaderCss(isMobile, minimizedSideBar, true),
          toolsRoutes.indexOf(active) > -1 && minimizedSideBar ? 'active' : '',
        )}
        onClick={handleClick}
      >
        <div className="notch"></div>
        <div
          className={css`
            display: flex;
          `}
        >
          <Image src={ToolsIcon} className={iconCss(minimizedSideBar)} alt="tools-icon" width="16px" height="16px" />
          {!minimizedSideBar && <Trans id="sidebar.buttons.tools" />}
          {!minimizedSideBar && newLabel()}
        </div>
        {!minimizedSideBar && (
          <Image
            className={menus.toolsMenu ? 'expanded' : 'collapsed'}
            src={Shevron}
            alt="tools-shevron"
            width="16px"
            height="16px"
          />
        )}
      </Menu.Header>
      {!minimizedSideBar && (
        <ToolsItems
          menus={menus}
          minimizedSideBar={minimizedSideBar}
          isMobile={isMobile}
          active={active}
          onNavLinkClick={onNavLinkClick}
        />
      )}
    </>
  )
}

export default ToolsMenu
