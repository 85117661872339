import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { CustomViewStore } from '@pp/modules/analyse/common/custom-view/custom-view.store'
import { CustomViewConfiguration } from '@pp/modules/analyse/common/custom-view/types/custom-view.type'

export const getConfigurationPropertyFromEntityType = (entityType: EntityType) => {
  switch (entityType) {
    case EntityType.Agent:
      return 'lawFirmConfiguration'
    case EntityType.Attorney:
      return 'attorneyConfiguration'
    case EntityType.Applicant:
      return 'applicantConfiguration'
  }
}

export const createSlug = (input: string): string => {
  return input
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
    .replace(/^-+|-+$/g, '') // Trim hyphens from the start and end
    .replace(/-{2,}/g, '-') // Replace multiple hyphens with a single one
}

export const getViewsForEntityType = (
  customViewStore: CustomViewStore,
  entityType: string,
): CustomViewConfiguration[] | undefined => {
  switch (entityType) {
    case EntityType.Agent:
      return customViewStore.lawFirmViews
    case EntityType.Applicant:
      return customViewStore.applicantViews
    case EntityType.Attorney:
      return customViewStore.attorneyViews
  }
}
