import { MappedFilterType } from '@pp/common/components/filters/typescript/filters.type'
import { ChartEndpoints, AdvancedSearch } from '@pp/common/typescript/helpers.type'
import _ from 'lodash'

export const parseApplicantFilter = (
  filters: MappedFilterType,
  urls: string[],
  chartEndpoints: ChartEndpoints,
  isGroup = false,
) => {
  const clonedFilters = _.cloneDeep(filters) as AdvancedSearch
  const applicantUrl = isGroup ? { applicant_group_url: urls } : { applicant_url: urls }
  const groupSearch = isGroup ? { group_search: true } : {}

  clonedFilters.advanced_search = { chart_endpoints: chartEndpoints }
  clonedFilters.applicant = applicantUrl
  const finaFilters = { ...clonedFilters, ...groupSearch }

  return finaFilters
}
