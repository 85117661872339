import React from 'react'

import { css, cx } from '@emotion/css'
import { t } from '@lingui/macro'
import { printCss } from '@pp/common/chart/config/charts-config'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { useStores } from '@pp/store/useStore.hook'
import { Dropdown, Popup } from 'semantic-ui-react'

import exportTypeToHtmlItem from './ExportTypeToHtmlItem'
import Icon from '../../../../components/icon/Icon'
import { ExportChartType } from '../ChartHolder'

const getExportTypes = (type: boolean | ExportChartType[]): ExportChartType[] => {
  if (typeof type === 'boolean')
    return type ? [ExportChartType.JPEG, ExportChartType.SVG, ExportChartType.PDF, ExportChartType.XLSX] : []

  return type.sort()
}

type Props = {
  hasExport: boolean | ExportChartType[]
  isChart?: boolean
  isMobile: boolean
  onExport?: (payload: ExportChartType) => void
  testId: string
}

const DropDown = ({ hasExport, isChart = true, isMobile, onExport, testId }: Props) => {
  const { applicationStore } = useStores()
  const hasExportPermission = applicationStore.hasExportPermission
  const exportTypes = getExportTypes(hasExport)

  const exportChart = (type: ExportChartType) => () => {
    if (onExport) onExport(type)
  }

  const openModal = () => {
    if (hasExportPermission) return
    applicationStore.setModal({ open: true, version: '' })
  }

  const dropDownItems = exportTypes.map(exportTypeToHtmlItem(exportChart, !!hasExportPermission))
  if (!hasExport) dropDownItems.push()

  return (
    <Dropdown
      inline
      direction="left"
      icon={null}
      trigger={
        <Popup
          content={isChart ? t`profile.exportChart.tooltip` : t`profile.exportData.tooltip`}
          trigger={<Icon iconName="icon-export" size={14} margin="0 0 0 8px" onClick={openModal} />}
          on="hover"
        />
      }
      className={cx(
        css`
          ${increaseSpecificity(`
              border: none !important;
              color: #4c4c4c !important;
              min-height: 20px !important;
              height: 20px !important;
              padding: 0 !important;
            `)}
        `,
        printCss,
      )}
      data-test-id={`${testId}-export-dropdown`}
    >
      <Dropdown.Menu direction={isMobile ? 'left' : 'right'}>{dropDownItems}</Dropdown.Menu>
    </Dropdown>
  )
}

export default DropDown
