import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import InsightsIcon from '@pp/assets/icons/insights-menu-icon.svg'
import Shevron from '@pp/assets/icons/shevron-icon.svg'
import { Menu, Image, Label } from 'semantic-ui-react'

import InsightsItems from './InsightsItems'
import { checkRoutes } from '../../../route-groups'
import { iconCss, menuHeaderCss } from '../../../sidebar.css'
import { MenuProps } from '../analyze-menu/AnalyzeMenu'

export const newLabel = () => (
  <Label
    className={cx(
      '',
      css`
        background-color: var(--primary-color) !important;
        color: white !important;
        font-size: 8px !important;
        margin-left: 4px !important;

        &.active {
          background-color: white !important;
          color: var(--primary-color) !important;
        }
      `,
    )}
  >
    <Trans id="Pro" />
  </Label>
)

const InsightsMenu = ({
  active,
  minimizedSideBar,
  menus,
  setShowMenus,
  isMobile,
  toggleSidebar,
  onNavLinkClick,
  isSmallHeight,
}: MenuProps) => {
  const handleClick = () => {
    if (minimizedSideBar) {
      toggleSidebar && toggleSidebar()
    } else {
      if (isMobile || isSmallHeight) {
        setShowMenus((prevState) => ({
          analyzeMenu: false,
          searchMenu: false,
          checkMenu: !prevState.checkMenu,
          resourceSubmenu: false,
          toolsMenu: false,
        }))
      } else {
        setShowMenus((prevState) => ({
          analyzeMenu: prevState.analyzeMenu,
          searchMenu: prevState.searchMenu,
          checkMenu: !prevState.checkMenu,
          resourceSubmenu: prevState.resourceSubmenu,
          toolsMenu: prevState.toolsMenu,
        }))
      }
    }
  }

  return (
    <>
      <Menu.Header
        className={cx(
          menuHeaderCss(isMobile, minimizedSideBar, true),
          checkRoutes.indexOf(active) > -1 && minimizedSideBar ? 'active' : '',
        )}
        onClick={handleClick}
      >
        <div className="notch"></div>
        <div
          className={css`
            display: flex;
          `}
        >
          <Image
            src={InsightsIcon}
            className={iconCss(minimizedSideBar)}
            alt="analyse-shevron"
            width="16px"
            height="16px"
          />
          {minimizedSideBar ? null : <Trans id="sidebar.buttons.check" />}
          {minimizedSideBar ? null : newLabel()}
        </div>
        {minimizedSideBar ? null : (
          <Image
            className={menus.checkMenu ? 'expanded' : 'collapsed'}
            src={Shevron}
            alt="analyse-shevron"
            width="16px"
            height="16px"
          />
        )}
      </Menu.Header>
      {minimizedSideBar ? null : (
        <InsightsItems
          menus={menus}
          minimizedSideBar={minimizedSideBar}
          isMobile={isMobile}
          active={active}
          onNavLinkClick={onNavLinkClick}
        />
      )}
    </>
  )
}

export default InsightsMenu
