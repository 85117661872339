import * as R from 'ramda'

const sectors = [
  {
    id: 1,
    sector: 'Electrical engineering',
    fields: [
      { id: 1, field: 'Electrical machinery, apparatus, energy' },
      { id: 2, field: 'Audio-visual technology' },
      { id: 3, field: 'Telecommunications' },
      { id: 4, field: 'Digital communication' },
      { id: 5, field: 'Basic communication processes' },
      { id: 6, field: 'Computer technology' },
      { id: 7, field: 'IT methods for management' },
      { id: 8, field: 'Semiconductors' },
    ],
  },
  {
    id: 2,
    sector: 'Instruments',
    fields: [
      { id: 9, field: 'Optics' },
      { id: 10, field: 'Measurement' },
      { id: 11, field: 'Analysis of biol. materials' },
      { id: 12, field: 'Control' },
      { id: 13, field: 'Medical technology' },
    ],
  },
  {
    id: 3,
    sector: 'Chemistry',
    fields: [
      { id: 14, field: 'Organic fine chemistry' },
      { id: 15, field: 'Biotechnology' },
      { id: 16, field: 'Pharmaceuticals' },
      { id: 17, field: 'Macromolecular chemistry, polymers' },
      { id: 18, field: 'Food chemistry' },
      { id: 19, field: 'Basic materials chemistry' },
      { id: 20, field: 'Materials, metallurgy' },
      { id: 21, field: 'Surface technology, coating' },
      { id: 22, field: 'Micro-structural and nano-technology' },
      { id: 23, field: 'Chemical engineering' },
      { id: 24, field: 'Environmental technology' },
    ],
  },
  {
    id: 4,
    sector: 'Mechanical engineering',
    fields: [
      { id: 25, field: 'Handling' },
      { id: 26, field: 'Machine tools' },
      { id: 27, field: 'Engines, pumps, turbines' },
      { id: 28, field: 'Textile and paper machines' },
      { id: 29, field: 'Other special machines' },
      { id: 30, field: 'Thermal processes and apparatus' },
      { id: 31, field: 'Mechanical elements' },
      { id: 32, field: 'Transport' },
    ],
  },
  {
    id: 5,
    sector: 'Other fields',
    fields: [
      { id: 33, field: 'Furniture, games' },
      { id: 34, field: 'Other consumer goods' },
      { id: 35, field: 'Civil engineering' },
    ],
  },
]

const fallbackSector = {
  id: 0,
  sector: 'Unknown',
  fields: [],
}

export const getTechnicalSectors = () => sectors
export const getTechnicalFields = () => R.flatten(R.map(R.prop('fields'), sectors))

export const getSectorFromField = (field: string) => {
  const sector = R.find(({ fields }) => !!R.find(({ id }) => R.equals(id.toString(), field), fields), sectors)
  return sector || fallbackSector
}

export const getNameOfField = (field: string) => {
  const found = R.find(({ id }) => id === Number(field), getTechnicalFields())
  return found && found.field
}

export const getNameOfSector = (sector: string | number) => {
  const found = sectors.find(({ id }) => id === Number(sector))
  return found && found.sector
}

export const getSectorFromId = (id: number) => {
  if (id <= 0 || id > getTechnicalSectors().length) return fallbackSector
  return getTechnicalSectors()[id - 1]
}

export const getSectorsFields = (ids: string[]) => {
  return R.flatten(R.map((id) => getSectorFromId(Number(id)).fields, ids))
}
