import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { FilterType } from '@pp/common/components/filters/filter.store'
import { Entity } from '@pp/common/typescript/meta.interface'
import { ChartFilterType } from '@pp/modules/main/widgets/charts/ChartsWidget'

export const prepareFilters = (
  filters: FilterType,
  entityType: EntityType,
  metadata: Entity[],
  entities: string[],
): ChartFilterType => {
  const preparedFilters: ChartFilterType = { ...filters, entityType: [entityType], metadata }

  if (entityType === EntityType.Agent) {
    preparedFilters.rep_url = entities
  } else {
    preparedFilters.applicant_url = entities
  }

  return preparedFilters
}
