import { css } from '@emotion/css'
import { Trans } from '@lingui/react'
import { Icon } from 'semantic-ui-react'

import { iconContainerCss } from '../default-failure/DefaultFailure'

export const getNoDataContainerCss = (customStyles?: string, containerHeight: string | undefined = '250px') => {
  const defaultStyles = css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#b3b3b3',
    textAlign: 'center',
    height: `${containerHeight}`,
  })

  return css`
    ${defaultStyles};
    ${customStyles};
  `
}

type NoDataProps = { containerHeight?: string; containerStyles?: string; noDataTitle?: string }

function NoData({ containerStyles, containerHeight, noDataTitle }: NoDataProps) {
  return (
    <div className={getNoDataContainerCss(containerStyles, containerHeight)}>
      <div className={iconContainerCss}>
        <Icon name={'ellipsis horizontal'} />
      </div>
      <div>
        <p>{noDataTitle ? noDataTitle : <Trans id="noData.default.message" />}</p>
      </div>
    </div>
  )
}

export default NoData
