import { Dispatch, SetStateAction } from 'react'

import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import AnalyseIcon from '@pp/assets/icons/analyze-menu-icon.svg'
import Shevron from '@pp/assets/icons/shevron-icon.svg'
import { Menus } from '@pp/common/components/navigation/sidebar/components/sidebar-menus/VerticalMenus'
import { observer } from 'mobx-react-lite'
import { Menu, Image } from 'semantic-ui-react'

import AnalyzeItems from './AnalyzeItems'
import { analyzeRoutes } from '../../../route-groups'
import { iconCss, menuHeaderCss } from '../../../sidebar.css'

export type MenuProps = {
  active: string
  isMobile: boolean
  isSmallHeight?: boolean
  menus: Menus
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
  setShowMenus: Dispatch<SetStateAction<Menus>>
  toggleSidebar?: () => void
}

const AnalyzeMenu = ({
  active,
  minimizedSideBar,
  menus,
  setShowMenus,
  isMobile,
  toggleSidebar,
  onNavLinkClick,
  isSmallHeight,
}: MenuProps) => {
  const handleClick = () => {
    if (minimizedSideBar) {
      toggleSidebar && toggleSidebar()
    } else {
      if (isMobile || isSmallHeight) {
        setShowMenus((prevState) => ({
          analyzeMenu: !prevState.analyzeMenu,
          searchMenu: false,
          checkMenu: false,
          resourceSubmenu: false,
          toolsMenu: false,
        }))
      } else {
        setShowMenus((prevState) => ({
          analyzeMenu: !prevState.analyzeMenu,
          searchMenu: prevState.searchMenu,
          checkMenu: prevState.checkMenu,
          resourceSubmenu: prevState.resourceSubmenu,
          toolsMenu: prevState.toolsMenu,
        }))
      }
    }
  }

  return (
    <>
      <Menu.Header
        className={cx(
          menuHeaderCss(isMobile, minimizedSideBar, true),
          analyzeRoutes.indexOf(active) > -1 && minimizedSideBar ? 'active' : '',
        )}
        onClick={handleClick}
      >
        <div className="notch"></div>
        <div
          className={css`
            display: flex;
          `}
        >
          <Image
            src={AnalyseIcon}
            className={iconCss(minimizedSideBar)}
            alt="analyse-shevron"
            width="16px"
            height="16px"
          />
          {minimizedSideBar ? null : <Trans id="sidebar.buttons.analyze" />}
        </div>
        {minimizedSideBar ? null : (
          <Image
            className={menus.analyzeMenu ? 'expanded' : 'collapsed'}
            src={Shevron}
            alt="analyse-shevron"
            width="16px"
            height="16px"
          />
        )}
      </Menu.Header>
      {minimizedSideBar ? null : (
        <AnalyzeItems
          menus={menus}
          minimizedSideBar={minimizedSideBar}
          isMobile={isMobile}
          active={active}
          onNavLinkClick={onNavLinkClick}
        />
      )}
    </>
  )
}

export default observer(AnalyzeMenu)
