import React from 'react'

import { useMutation } from '@apollo/client'
import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import { printCss } from '@pp/common/chart/config/charts-config'
import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { ChartWidgetType, GridConfiguration } from '@pp/common/typescript/dashboard.type'
import {
  createSlug,
  getConfigurationPropertyFromEntityType,
  getViewsForEntityType,
} from '@pp/modules/analyse/common/custom-view/helpers/custom-view.helpers'
import { SAVE_CUSTOM_VIEW } from '@pp/modules/analyse/common/custom-view/helpers/custom-view.queries'
import { CustomView, CustomViewConfiguration } from '@pp/modules/analyse/common/custom-view/types/custom-view.type'
import { useStores } from '@pp/store/useStore.hook'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import omitDeep from 'omit-deep-lodash'
import { useLocation } from 'react-router'
import { Dropdown, Popup } from 'semantic-ui-react'
import { v4 as uuid } from 'uuid'

import Icon from '../../../../../components/icon/Icon'
import { ChartType } from '../../../../options/get-preview-chart-options'

function AddCustomView({ chartType, entityType, id }: { chartType: ChartType; entityType: EntityType; id: string }) {
  const { customViewStore } = useStores()
  const customViews = getViewsForEntityType(customViewStore, entityType)
  const [saveCustomViewConfiguration] = useMutation(SAVE_CUSTOM_VIEW, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data: { saveCustomViewConfiguration: CustomView }) => {
      customViewStore.setCustomView(data.saveCustomViewConfiguration)
    },
  })
  const location = useLocation()
  const showDelete = location.pathname.includes('/custom-view')
  const currentView = customViews?.find((view) =>
    location.pathname.includes(createSlug(view.customViewName)),
  ) as CustomViewConfiguration

  const options = customViews?.map((customView) => ({
    key: customView._id,
    text: customView.customViewName,
    value: customView.customViewName,
  }))

  const onCustomViewAddChart = (customViewId: string) => {
    const viewToAdd = customViews?.find((view) => view._id === customViewId) as CustomViewConfiguration
    //Add chart to the first empty column in the first available row
    const newConfiguration = _.cloneDeep(viewToAdd.configuration)
    const rows = Object.keys(newConfiguration)
    let added = false

    for (const row of rows) {
      const columns = newConfiguration[row]?.columns
      if (columns?.length && columns.length < 3) {
        columns?.push([
          {
            _id: chartType,
            id: `chartsWidget_${uuid()}`,
            name: chartType,
            content: null,
            chartType: chartType as unknown as ChartWidgetType,
          },
        ])
        added = true
        break
      }
      if (added) {
        break
      }
    }

    if (customViewStore.customView) {
      const customViewInput = {
        ...customViewStore.customView,
      }
      const currentConfigurationIndex = customViewInput[getConfigurationPropertyFromEntityType(entityType)].findIndex(
        (cv) => cv._id === viewToAdd._id,
      )
      const dto = _.cloneDeep(newConfiguration)
      const cleanedDto = omitDeep(dto, 'content') as GridConfiguration
      customViewInput[getConfigurationPropertyFromEntityType(entityType)][currentConfigurationIndex] = {
        ...viewToAdd,
        configuration: cleanedDto,
      }
      const cleanedInput = omitDeep(_.omitBy(_.cloneDeep(customViewInput), _.isEmpty), '__typename') as CustomView
      saveCustomViewConfiguration({
        variables: {
          customView: cleanedInput,
        },
      })
    }
  }

  const onRemoveChart = () => {
    const newConfiguration = _.cloneDeep(currentView.configuration)
    // Find the chart in configuration and remove it by id
    const rows = Object.keys(newConfiguration)

    for (const row of rows) {
      const columns = newConfiguration[row]?.columns
      if (columns) {
        for (const column of columns) {
          const chartIndex = column.findIndex((chart) => chart.id === id)
          if (chartIndex !== -1) {
            column.splice(chartIndex, 1)
          }
        }
      }
    }

    if (customViewStore.customView) {
      const customViewInput = {
        ...customViewStore.customView,
      }
      const currentConfigurationIndex = customViewInput[getConfigurationPropertyFromEntityType(entityType)].findIndex(
        (cv) => cv._id === currentView._id,
      )
      customViewInput[getConfigurationPropertyFromEntityType(entityType)][currentConfigurationIndex] = {
        ...currentView,
        configuration: newConfiguration,
      }
      const cleanedInput = omitDeep(_.omitBy(_.cloneDeep(customViewInput), _.isEmpty), '__typename') as CustomView
      saveCustomViewConfiguration({
        variables: {
          customView: cleanedInput,
        },
      })
    }
  }

  if (showDelete) {
    return (
      <Popup
        content={<Trans id="profile.removeChart.button" />}
        trigger={<Icon iconName="icon-remove" size={14} margin="3px 0 0 4px" onClick={onRemoveChart} />}
      />
    )
  }

  return (
    <Dropdown
      inline
      icon={null}
      direction="left"
      trigger={
        <Popup
          content={<Trans id="profile.addCustomView.button" />}
          trigger={<Icon iconName="icon-plus" size={14} margin="3px 0 0 4px" />}
          on="hover"
        />
      }
      className={cx(
        css`
          ${increaseSpecificity(`
              border: none !important;
              padding: 0 !important;
              min-height: 20px !important;
              height: 20px !important;
            `)}
        `,
        printCss,
      )}
    >
      <Dropdown.Menu>
        {options?.map((option) => (
          <Dropdown.Item onClick={() => onCustomViewAddChart(option.key)}>{option.text}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default observer(AddCustomView)
