import React from 'react'

import { css } from '@emotion/css'
import { EntityType } from '@pp/common/components/entity-name-search/entity-search.interface'
import { increaseSpecificity } from '@pp/common/css/css.helper'
import { Header, Popup } from 'semantic-ui-react'

import { DropDown, SaveToDashboard, Tooltip } from '.'
import AddCustomView from './add-custom-view/AddCustomView'
import NewLabel from '../../../../components/navigation/sidebar/components/NewLabel'
import { ChartType } from '../../../options/get-preview-chart-options'
import { ExportChartType } from '../ChartHolder'

const headerCss = css`
  ${increaseSpecificity(`
  display: flex;
  justify-content: space-between;
  margin: 0 0 1em 0;
  `)}
`

const titleCss = css`
  margin-right: 0px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #4c4c4c;
`

type Props = {
  chartType?: string
  entityType: EntityType
  id?: string
  isMobile: boolean
  isProfile?: boolean
  jsxElements: { datesDropDown?: JSX.Element; fieldsDropDown?: JSX.Element; mobileWarning?: JSX.Element | null }
  onExport?: (payload: ExportChartType) => void
  permissions: {
    hasCustomView: boolean
    hasDashboard: boolean
    hasExport: boolean | ExportChartType[]
    isBeta?: boolean
    isPdfExport: boolean
  }
  testId: string
  title: string
  tooltip?: string
}

const ChartHeader = ({
  chartType,
  isMobile,
  jsxElements,
  onExport,
  permissions,
  testId,
  title,
  tooltip,
  entityType,
  id = '',
  isProfile = true,
}: Props) => {
  const { hasDashboard, hasCustomView, hasExport, isBeta, isPdfExport } = permissions
  const { fieldsDropDown, datesDropDown, mobileWarning } = jsxElements
  return (
    <Header textAlign="left" as="h3" className={headerCss}>
      <div
        className={css`
          display: inline-flex;
          text-align: left;
          font-size: 14px !important;
          justify-content: flex-start;
        `}
      >
        <div className={titleCss} dangerouslySetInnerHTML={{ __html: title }}></div>
        {tooltip && !isPdfExport ? <Tooltip testId={testId} tooltip={tooltip} /> : null}
      </div>
      <div
        className={css`
          display: inline-flex;
          align-items: flex-start;
        `}
      >
        {hasDashboard && !isPdfExport ? <SaveToDashboard chartType={chartType} testId={testId} title={title} /> : null}
        {fieldsDropDown ? fieldsDropDown : null}
        {datesDropDown ? datesDropDown : null}
        {mobileWarning ? mobileWarning : null}
        {hasExport && !isPdfExport ? (
          <DropDown hasExport={hasExport} onExport={onExport} testId={testId} isMobile={isMobile} />
        ) : null}
        {hasCustomView && !isPdfExport && isProfile ? (
          <AddCustomView chartType={chartType as ChartType} entityType={entityType} id={id} />
        ) : null}
        {isBeta ? <Popup content="Beta" on="hover" trigger={<NewLabel relative />} /> : null}
      </div>
    </Header>
  )
}

export default ChartHeader
