import { EntitySearchResult, EntityType } from '../components/entity-name-search/entity-search.interface'
import { Entity } from '../typescript/dashboard.type'

export const mapRecentSearch = (data: EntitySearchResult[], entityType: EntityType): Entity[] => {
  return data.map((searchResult) => ({
    country: searchResult?.country || 'xx', // 'xx' is a country & flag placeholder if no country
    entityType,
    name: searchResult.name,
    urlIdentifier: searchResult.value,
  }))
}
