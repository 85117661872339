import { gql } from '@apollo/client'

export const SAVE_CUSTOM_VIEW = gql`
  mutation saveCustomViewConfiguration($customView: CustomViewInput!) {
    saveCustomViewConfiguration(customView: $customView) {
      _id
      attorneyConfiguration {
        _id
        customViewName
        configuration {
          row1 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row2 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row3 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row4 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row5 {
            columns {
              _id
              id
              chartType
              name
            }
          }
        }
      }
      lawFirmConfiguration {
        _id
        customViewName
        configuration {
          row1 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row2 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row3 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row4 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row5 {
            columns {
              _id
              id
              chartType
              name
            }
          }
        }
      }
      applicantConfiguration {
        _id
        customViewName
        configuration {
          row1 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row2 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row3 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row4 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row5 {
            columns {
              _id
              id
              chartType
              name
            }
          }
        }
      }
    }
  }
`

export const GET_CUSTOM_VIEW_CONFIGURATION_NAMES = gql`
  query getCustomViewConfigurationNames {
    getCustomViewConfigurationNames {
      _id
      attorneyConfiguration {
        _id
        customViewName
      }
      lawFirmConfiguration {
        _id
        customViewName
      }
      applicantConfiguration {
        _id
        customViewName
      }
    }
  }
`

export const GET_CUSTOM_VIEW_CONFIGURATIONS = gql`
  query getCustomViewConfigurations {
    getCustomViewConfigurations {
      _id
      attorneyConfiguration {
        _id
        customViewName
        configuration {
          row1 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row2 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row3 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row4 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row5 {
            columns {
              _id
              id
              chartType
              name
            }
          }
        }
      }
      lawFirmConfiguration {
        _id
        customViewName
        configuration {
          row1 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row2 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row3 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row4 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row5 {
            columns {
              _id
              id
              chartType
              name
            }
          }
        }
      }
      applicantConfiguration {
        _id
        customViewName
        configuration {
          row1 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row2 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row3 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row4 {
            columns {
              _id
              id
              chartType
              name
            }
          }
          row5 {
            columns {
              _id
              id
              chartType
              name
            }
          }
        }
      }
    }
  }
`
